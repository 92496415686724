<template>
<v-container fluid>
  <v-card>
      <v-card-title>Upload Products</v-card-title>
      <v-card-text>
      Uploads are not available yet
      <!-- <v-form ref="form" v-model="valid" lazy-validation>
        <v-file-input
          label="Select File"
          :rules="fileInputRules"
          name='products'
          id='products'
          v-model="selectedFile"
          prepend-icon="mdi-microsoft-excel"
        ></v-file-input>
        <v-container class="pt-0">
          <v-row justify="start" align="center">
            <v-btn
              :disabled="!selectedFile"
              color="success"
              class="mr-4"
              @click="submitData"
            >
              Upload
            </v-btn>
            <v-checkbox
              true-value="preview"
              false-value="update"
              @change="uploadSuccess = false"
              v-model="mode"
              :label="`Preview Only`"
            ></v-checkbox>
          </v-row>
          <v-row justify="start" align="center">
          </v-row>
        </v-container>
      </v-form> -->
      </v-card-text>
  </v-card>
      <template v-if="processing">
      <v-container class="px-0">
        <v-progress-linear
          color="light-blue"
          height="10"
          :value="progress"
          striped
        ></v-progress-linear>
      </v-container>
      </template>
      <v-container class="px-0" v-if="uploadSuccess">
        <v-alert dense text type="success" v-if="mode === 'update'">
          Products uploaded successfully
        </v-alert>
        <v-alert dense text type="info" v-if="mode === 'preview'">
          This is a preview of the changes that would occur. No products have been updated
        </v-alert>
        <display-summary class="mt-2" :uploadStats="uploadStats"></display-summary>
        <display-updates class="mt-2" v-if="hasUpdates" :updated="uploadStats.updated"></display-updates>
        <display-inserts class="mt-2" v-if="hasInserts" :inserted="uploadStats.inserted"></display-inserts>
      </v-container>
      <v-container class="px-0" v-if="validationErrors.length">
        <v-alert
          dense
          text
          type="error"
        >
          The uploaded spreadsheet has failed validation with the following errors
        </v-alert>
        <v-data-table
          :headers="validationHeaders"
          :items="validationErrors"
          :disable-pagination="true"
          :hide-default-footer=true
          dense
        >
        </v-data-table>
      </v-container>
</v-container>
</template>

<script>
import DisplayUpdates from './components/DisplayUpdates.vue'
import DisplaySummary from './components/DisplaySummary.vue'
import DisplayInserts from './components/DisplayInserts.vue'

const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/admin/product/upload`

export default {
  components: {
    DisplayUpdates,
    DisplaySummary,
    DisplayInserts
  },
  data () {
    return {
      fileInputRules: [
        v => !!v || 'File is required'
      ],
      validationErrors: [],
      validationHeaders: [
        { text: 'Sheet', value: 'sheet' },
        { text: 'Description', value: 'description' },
        { text: 'Detail', value: 'detail' }
      ],
      uploadStats: {},
      uploadHeaders: [
        { text: 'Inserted', value: 'inserted.length' },
        { text: 'Updated', value: 'updated' },
        { text: 'Unchanged', value: 'unchanged' },
        { text: 'Images missing', value: 'missingImages.length' },
        { text: 'Total', value: 'total' }
      ],
      updatedHeaders: [
        { text: 'Product', value: 'sku' },
        { text: 'Field', value: 'field' },
        { text: 'Old', value: 'old' },
        { text: 'New', value: 'new' }
      ],
      selectedFile: null,
      validData: false,
      valid: true,
      processing: false,
      progress: 0,
      uploadSuccess: false,
      mode: 'preview'
    }
  },
  async created () {},
  mounted: function () {
  },
  computed: {
    hasInserts: function () { return this.uploadStats.inserted && this.uploadStats.inserted.length },
    hasValidationErrors: function () { return this.validationErrors && this.validationErrors.length },
    hasUpdates: function () { return this.uploadStats.updated && this.uploadStats.updated.length },
    hasMissingImages: function () { return this.uploadStats.missingImages && this.uploadStats.missingImages.length }
  },
  watch: {},
  methods: {
    submitData () {
      const formData = new FormData()
      this.validationErrors = []
      this.uploadSuccess = false
      this.uploadStats = {}
      this.progress = 0
      this.processing = true
      formData.append('mode', this.mode)
      formData.append('products', this.selectedFile)
      const HOST = process.env.NODE_ENV === 'development' ? `ws://localhost:${process.env.VUE_APP_PORT}` : location.origin.replace(/^http/, 'ws')
      const connection = new WebSocket(HOST)
      connection.addEventListener('open', function (event) {
        connection.send(12345)
      })
      connection.onmessage = (event) => {
        const payload = JSON.parse(event.data)
        switch (payload.message) {
          case 'progress':
            this.progress = payload.data
            break
          case 'complete':
            connection.close()
            this.processing = false
            this.uploadSuccess = true
            break
          case 'stats':
            this.uploadStats = payload.data
            break
          case 'validationErrors':
            this.processing = false
            this.validationErrors = payload.data
            break
          default:
            break
        }
        // if (event.data.message === 'complete')
        //   this.progress = event.data
      }

      this.$axios.post(api,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(data => {
        })
        .catch(data => {
          console.log('error', data)
        })
    },
    reset () {}
  }
}
</script>
